<template>
    <el-form ref="form" :inline="true" :model="form" size="small" :rules="rules" label-position="top">
        <div class="admin_page_item mt18 pr0 pb0">
            <h1 class="title">基本信息</h1>
            <div class="data_detail mt24">
                <el-form-item label="公司名称" prop="corporateName">
                    <el-input v-model="form.corporateName" placeholder="请输入公司名称"></el-input>
                </el-form-item>

                <el-form-item label="公司所在地" prop="corporateProvince">
                    <el-cascader :options="regionData" v-model="corporateAddress" @change="corporateAddressChange" placeholder="请选择公司所在地" :props="{ value: 'label' }"></el-cascader>
                </el-form-item>

                <el-form-item label="公司详细地址" prop="corporateAddress">
                    <el-input v-model="form.corporateAddress" placeholder="请输入公司详细地址"></el-input>
                </el-form-item>

                <el-form-item label="公司电话" prop="corporatePhone">
                    <el-input v-model="form.corporatePhone" placeholder="请输入公司电话"></el-input>
                </el-form-item>

                <el-form-item label="员工总数" prop="corporatePeopleNum">
                    <div class="item">
                        <el-input v-model.number="form.corporatePeopleNum" placeholder="请输入员工总数"></el-input>
                        <span class="text">人</span>
                    </div>
                </el-form-item>

                <el-form-item label="注册资金" prop="corporateRegisteredPrice">
                    <div class="item">
                        <el-input v-model="form.corporateRegisteredPrice" placeholder="请输入注册资金"></el-input>
                        <span class="text">万元</span>
                    </div>
                </el-form-item>

                <el-form-item label="联系人姓名" prop="corporateContactName">
                    <el-input v-model="form.corporateContactName" placeholder="请输入联系人姓名"></el-input>
                </el-form-item>

                <el-form-item label="联系人电话" prop="corporateContactPhone">
                    <el-input v-model="form.corporateContactPhone" placeholder="请输入联系人电话"></el-input>
                </el-form-item>

                <el-form-item label="电子邮箱" prop="corporateContactMail">
                    <el-input v-model="form.corporateContactMail" placeholder="请输入电子邮箱"></el-input>
                </el-form-item>
            </div>
        </div>

        <div class="admin_page_item mt18 pr0 pb0">
            <h1 class="title">营业执照信息</h1>
            <div class="data_detail mt24">
                <el-form-item label="营业执照号" prop="businessLicenseNo">
                    <el-input v-model="form.businessLicenseNo" placeholder="请输入营业执照号"></el-input>
                </el-form-item>

                <el-form-item label="法定经营范围" class="full" prop="legalBusinessScope">
                    <el-input v-model="form.legalBusinessScope" type="textarea" :rows="4" placeholder="请输入法定经营范围"></el-input>
                </el-form-item>

                <el-form-item label="证件上传" class="full" prop="businessLicenseImage">
                    <div class="flexColumn">
                        <div class="uploadExample">
                            <el-upload
                                    class="avatar-uploader"
                                    :action="uploadURLSeal"
                                    :before-upload="beforeUploadImage"
                                    :on-success="businessLicenseImageUpload"
                                    :show-file-list="false">
                                <img v-if="form.businessLicenseImage" :src="imageURL + form.businessLicenseImage" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                            <div class="example">
                                <el-image fit="contain"
                                        class="avatar"
                                        :src="require('../../../../assets/img/example/example_img01.png')"
                                        :preview-src-list="[require('../../../../assets/img/example/example_img01.png')]">
                                </el-image>
                                <div class="desc">示例图片</div>
                            </div>
                        </div>
                        <div class="el-upload__tip">支持扩展名：.jpg、.jpeg、.png格式，图片大小不超过10M</div>
                    </div>
                </el-form-item>
            </div>
        </div>

        <div class="admin_page_item mt18 pr0 pb0">
            <h1 class="title">法人信息</h1>
            <div class="data_detail mt24">
                <el-form-item label="法人姓名" prop="legalRepresentativeName">
                    <el-input v-model="form.legalRepresentativeName" placeholder="请输入法人姓名"></el-input>
                </el-form-item>

                <el-form-item label="法人证件号" prop="legalRepresentativeCardno">
                    <el-input v-model="form.legalRepresentativeCardno" placeholder="请输入法人证件号"></el-input>
                </el-form-item>

                <el-form-item label="证件有效期" :prop="form.legalRepresentativeCardValidityLong === 2 ? 'legalRepresentativeCardValidity' : 'legalRepresentativeCardValidityLong'">
                    <el-radio-group v-model="form.legalRepresentativeCardValidityLong">
                        <el-radio :label="1">长期有效</el-radio>
                        <el-radio :label="2">非长期</el-radio>
                    </el-radio-group>
                    <el-date-picker v-model="form.legalRepresentativeCardValidity" type="date" placeholder="请选择证件有效期" value-format="yyyy-MM-dd" :disabled="form.legalRepresentativeCardValidityLong === 1" style="width: 150px; float: right"></el-date-picker>
                </el-form-item>

                <el-form-item label="身份证人像面" class="full" prop="legalRepresentativeCardValidityImage01">
                    <div class="flexColumn">
                        <div class="uploadExample">
                            <el-upload
                                    class="avatar-uploader"
                                    :action="uploadURL"
                                    :before-upload="beforeUploadImage"
                                    :on-success="legalRepresentativeCardValidityImage01Upload"
                                    :show-file-list="false">
                                <img v-if="form.legalRepresentativeCardValidityImage01" :src="imageURL + form.legalRepresentativeCardValidityImage01" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                            <div class="example">
                                <el-image
                                        class="avatar" fit="contain"
                                        :src="require('../../../../assets/img/example/example_img02.png')"
                                        :preview-src-list="[require('../../../../assets/img/example/example_img02.png')]">
                                </el-image>
                                <div class="desc">示例图片</div>
                            </div>
                        </div>
                        <div class="el-upload__tip">支持扩展名：.jpg、.jpeg、.png格式，图片大小不超过10M</div>
                    </div>
                </el-form-item>

                <el-form-item label="身份证国徽面" class="full" prop="legalRepresentativeCardValidityImage02">
                    <div class="flexColumn">
                        <div class="uploadExample">
                            <el-upload
                                    class="avatar-uploader"
                                    :action="uploadURL"
                                    :before-upload="beforeUploadImage"
                                    :on-success="legalRepresentativeCardValidityImage02Upload"
                                    :show-file-list="false">
                                <img v-if="form.legalRepresentativeCardValidityImage02" :src="imageURL + form.legalRepresentativeCardValidityImage02" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                            <div class="example">
                                <el-image
                                        class="avatar" fit="contain"
                                        :src="require('../../../../assets/img/example/example_img03.png')"
                                        :preview-src-list="[require('../../../../assets/img/example/example_img03.png')]">
                                </el-image>
                                <div class="desc">示例图片</div>
                            </div>
                        </div>
                        <div class="el-upload__tip">支持扩展名：.jpg、.jpeg、.png格式，图片大小不超过10M</div>
                    </div>
                </el-form-item>
            </div>
        </div>

        <div class="page_footer mt24">
            <el-button type="primary" size="small" @click="submitForm('form')">下一步，填写财务资质信息</el-button>
        </div>
    </el-form>
</template>

<script>
  import { regionData } from 'element-china-area-data'
  import {imageURL, uploadURL, uploadURLSeal} from '../../../../utils/config'
  import {beforeUploadImage} from '../../../../utils/method'
  export default {
    name: "adminMerchantEditFirst",
    props: {
      infoData: {
        type: Object,
        default: () => {return {}}
      }
    },
    data () {
      return {
        form: {
          corporateName: '',
          corporateProvince: '',
          corporateCity: '',
          corporateArea: '',
          corporateAddress: '',
          corporatePhone: '',
          corporatePeopleNum: null,
          corporateRegisteredPrice: '',
          corporateContactName: '',
          corporateContactPhone: '',
          corporateContactMail: '',
          businessLicenseNo: '',
          legalBusinessScope: '',
          businessLicenseImage: '',
          legalRepresentativeName: '',
          legalRepresentativeCardno: '',
          legalRepresentativeCardValidityLong: 1,
          legalRepresentativeCardValidity: '',
          legalRepresentativeCardValidityImage01: '',
          legalRepresentativeCardValidityImage02: '',
        },
        rules: {
          corporateName: [
            { required: true, message: '请输入公司名称', trigger: 'blur' }
          ],
          corporateProvince: [
            { required: true, message: '请选择公司所在地', trigger: 'change' }
          ],
          corporateAddress: [
            { required: true, message: '请输入公司详细地址', trigger: 'blur' }
          ],
          corporatePhone: [
            { required: true, message: '请输入公司电话', trigger: 'blur' },
            // { pattern: '^([1]\\d{10}|([\\(（]?0[0-9]{2,3}[）\\)]?[-]?)?([2-9][0-9]{6,7})+(\\-[0-9]{1,4})?)$', message: '请输入正确的公司电话', trigger: 'blur'}
          ],
          corporatePeopleNum: [
            { required: true, message: '请输入员工总数', trigger: 'blur' }
          ],
          corporateRegisteredPrice: [
            { required: true, message: '请输入注册资金', trigger: 'blur' },
            { pattern: '^((^[1-9]\\d*)|^0)(\\.\\d{0,2}){0,1}$', message: '请输入正确的注册资金', trigger: 'blur'}
          ],
          corporateContactName: [
            { required: true, message: '请输入联系人姓名', trigger: 'blur' }
          ],
          corporateContactPhone: [
            { required: true, message: '请输入联系人电话', trigger: 'blur' },
            { pattern: '^([1]\\d{10}|([\\(（]?0[0-9]{2,3}[）\\)]?[-]?)?([2-9][0-9]{6,7})+(\\-[0-9]{1,4})?)$', message: '请输入正确的联系人电话', trigger: 'blur'}
          ],
          corporateContactMail: [
            { required: true, message: '请输入电子邮箱', trigger: 'blur' },
            { type: 'email', message: '请输入正确的电子邮箱', trigger: 'blur' }
          ],
          businessLicenseNo: [
            { required: true, message: '请输入营业执照号', trigger: 'blur' },
            { pattern: '^[A-Za-z0-9]+$', message: '请输入正确的营业执照号', trigger: 'blur'}
          ],
          legalBusinessScope: [
            { required: true, message: '请输入法定经营范围', trigger: 'blur' }
          ],
          businessLicenseImage: [
            { required: true, message: '请上传营业执照', trigger: 'change' }
          ],
          legalRepresentativeName: [
            { required: true, message: '请输入法人姓名', trigger: 'blur' }
          ],
          legalRepresentativeCardno: [
            { required: true, message: '请输入法人证件号', trigger: 'blur' },
            { pattern: '^[1-9]\\d{7}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])\\d{3}$|^[1-9]\\d{5}[1-9]\\d{3}((0\\d)|(1[0-2]))(([0|1|2]\\d)|3[0-1])\\d{3}([0-9]|X)$', message: '请输入正确的法人证件号', trigger: 'blur'}
          ],
          legalRepresentativeCardValidityLong: [
            { required: true, message: '请选择证件有效期', trigger: 'change' }
          ],
          legalRepresentativeCardValidity: [
            { required: true, message: '请选择证件有效期', trigger: 'change' }
          ],
          legalRepresentativeCardValidityImage01: [
            { required: true, message: '请上传身份证人像面', trigger: 'change' }
          ],
          legalRepresentativeCardValidityImage02: [
            { required: true, message: '请上传身份证国徽面', trigger: 'change' }
          ],
        },
        regionData: regionData,
        corporateAddress: [],
        imageURL: imageURL,
        uploadURL: uploadURL,
        uploadURLSeal: uploadURLSeal,
        beforeUploadImage: beforeUploadImage,
        pickerOptions: {
          shortcuts: [{
            text: '长期有效',
            onClick(picker) {
              picker.$emit('pick', '');
            }
          }]
        },
      }
    },
    watch: {
      infoData () {
        this.form.corporateName = this.infoData.corporateName
        this.form.corporateProvince = this.infoData.corporateProvince
        this.form.corporateCity = this.infoData.corporateCity
        this.form.corporateArea = this.infoData.corporateArea
        this.corporateAddress = [this.infoData.corporateProvince, this.infoData.corporateCity, this.infoData.corporateArea]
        this.form.corporateAddress = this.infoData.corporateAddress
        this.form.corporatePhone = this.infoData.corporatePhone
        this.form.corporatePeopleNum = this.infoData.corporatePeopleNum
        this.form.corporateRegisteredPrice = this.infoData.corporateRegisteredPrice
        this.form.corporateContactName = this.infoData.corporateContactName
        this.form.corporateContactPhone = this.infoData.corporateContactPhone
        this.form.corporateContactMail = this.infoData.corporateContactMail
        this.form.businessLicenseNo = this.infoData.businessLicenseNo
        this.form.legalBusinessScope = this.infoData.legalBusinessScope
        this.form.businessLicenseImage = this.infoData.businessLicenseImage
        this.form.legalRepresentativeName = this.infoData.legalRepresentativeName
        this.form.legalRepresentativeCardno = this.infoData.legalRepresentativeCardno
        this.form.legalRepresentativeCardValidityLong = this.infoData.legalRepresentativeCardValidityLong
        this.form.legalRepresentativeCardValidity = this.infoData.legalRepresentativeCardValidity
        this.form.legalRepresentativeCardValidityImage01 = this.infoData.legalRepresentativeCardValidityImage.split(',')[0]
        this.form.legalRepresentativeCardValidityImage02 = this.infoData.legalRepresentativeCardValidityImage.split(',')[1]
      }
    },
    methods: {
      corporateAddressChange (e) {
        this.form.corporateProvince = e[0]
        this.form.corporateCity = e[1]
        this.form.corporateArea = e[2]
      },
      businessLicenseImageUpload (res) {
        if (res.code === 0) {
          this.form.businessLicenseImage = res.data
        }
      },
      legalRepresentativeCardValidityImage01Upload (res) {
        if (res.code === 0) {
          this.form.legalRepresentativeCardValidityImage01 = res.data
        }
      },
      legalRepresentativeCardValidityImage02Upload (res) {
        if (res.code === 0) {
          this.form.legalRepresentativeCardValidityImage02 = res.data
        }
      },
      submitForm (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let data = {
              corporateName: this.form.corporateName,
              corporateProvince: this.form.corporateProvince,
              corporateCity: this.form.corporateCity,
              corporateArea: this.form.corporateArea,
              corporateAddress: this.form.corporateAddress,
              corporatePhone: this.form.corporatePhone,
              corporatePeopleNum: this.form.corporatePeopleNum,
              corporateRegisteredPrice: this.form.corporateRegisteredPrice,
              corporateContactName: this.form.corporateContactName,
              corporateContactPhone: this.form.corporateContactPhone,
              corporateContactMail: this.form.corporateContactMail,
              businessLicenseNo: this.form.businessLicenseNo,
              legalBusinessScope: this.form.legalBusinessScope,
              businessLicenseImage: this.form.businessLicenseImage,
              legalRepresentativeName: this.form.legalRepresentativeName,
              legalRepresentativeCardno: this.form.legalRepresentativeCardno,
              legalRepresentativeCardValidityLong: this.form.legalRepresentativeCardValidityLong,
              legalRepresentativeCardValidity: this.form.legalRepresentativeCardValidity,
              legalRepresentativeCardValidityImage: this.form.legalRepresentativeCardValidityImage01 + ',' + this.form.legalRepresentativeCardValidityImage02,
            }
            this.$emit('submit', data)
          } else {
            return false;
          }
        });
      }
    }
  }
</script>
